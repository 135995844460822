import React, { useReducer, useState } from 'react';
import Axios from 'axios';
import './App.css';

import GenerateInviteCodeForm from './GenerateInviteCodeForm';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import RSCU from 'react-simple-crud-ui';
import { CrudSettingModel, AuthSettingModel } from 'react-simple-crud-ui/dist/CRUD/Model';
import moment from 'moment';

function App() {
  const AuthenticationLayer = RSCU.components.AuthenticationLayer;
  const CrudStateModel = RSCU.models.CrudStateModel;

  const CRUD = RSCU.components.CRUD;
  const [appState, dispatchApp] = useReducer(RSCU.reducers.appReducer, new RSCU.models.AppStateModel());

  const reducer = {
    appState,
    dispatchApp
  }

  const inviteSetting = {
    initialCrudState: new CrudStateModel(100, 'INVITE'),
    Form: GenerateInviteCodeForm,
    ListGenerator: (crudState, dispatchCrud, mode) => {
      return (
        <div>
          <div className="row bold border-bottom">
            <div className="col-6">
              Usage
              </div>
            <div className="col-4">
              Expired Date
              </div>
            <div className="col-1">
              Used
              </div>
            <div className="col-1">
              Limit
              </div>
          </div>
          <div className="row bold border-bottom">
            <div className="col-6">
              <input className="form-control" placeholder="Filter By Usage" defaultValue={crudState.filter.value} onChange={(e) => dispatchCrud({ type: 'FILTER', payload: { value: e.target.value } })} />
            </div>
            <div className="col-4">
            </div>
            <div className="col-1">
            </div>
            <div className="col-1">
            </div>
          </div>
          {
            crudState.allDataPagination.map(i => {
              const key = new Date().getTime() + '' + i.id;
              const expiredDate = moment(new Date(parseInt(i.expireDate, 10))).format('DD-MM-YYYY HH:mm:ss');
              return (
                <div key={key} className="row data-record" title="Click to edit"
                  onClick={() => dispatchCrud({ type: 'CHANGE_MODE', payload: mode.Edit, editId: i.code })}>
                  <div className="col-6 grid-left ellipsis">
                    {i.usage}
                  </div>
                  <div className="col-4 grid-center ellipsis">
                    {expiredDate}
                  </div>
                  <div className="col-1 grid-right">
                    {i.used}
                  </div>
                  <div className="col-1 grid-right">
                    {i.limit}
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    },
    filterFn: (item: any, filterObject: any) => {
      return item.usage.toLowerCase().includes(filterObject.value.toLowerCase());
    },
    loadAllData: (appReducer, crudReducer, mounted: boolean) => {
      const { appState, dispatchApp } = appReducer;
      const { crudState, dispatchCrud } = crudReducer;
      Axios.post("https://account.api.duc168.com/duc-tran-api-account-graphql", {
        query: `query {
                  codes {
                  id
                  code
                  usage
                  expireDate
                  used
                  limit
                  usedUser
                  userId
              }
              }`
      }, {
        headers: {
          "duc-tran-api-account-token": appState.account.token
        }
      }).then(axiosData => {
        const data = axiosData.data;
        if (data.data !== undefined && data.data !== null) {
          if (!!mounted) {
            dispatchCrud({ type: 'GET_ALL_SUCCESS', payload: data.data.codes })
            if (crudState.filter !== '') {
              dispatchCrud({ type: 'FILTER', payload: crudState.filter })
            }
          } else {
            console.log('list is unmounted ', mounted);
          }
        } else {
          if (!!mounted) {
            dispatchCrud({ type: 'GET_ALL_FAIL', payload: JSON.stringify(data) })
          } else {
            console.log('list is unmounted ', mounted);
            console.log(data);
          }
        }
      }).catch(
        err => {
          console.log('list is unmounted ', mounted);
          console.log(err);
          if (!!mounted) {
            dispatchCrud({ type: 'GET_ALL_FAIL', payload: JSON.stringify(err) });
          }
          localStorage.removeItem('account');
          dispatchApp({ type: 'LOGOUT' });
        }
      );
    },
    addCrud: (appReducer, crudReducer, data) => {
      // eslint-disable-next-line
      const { appState, dispatchApp } = appReducer;
      // eslint-disable-next-line
      const { crudState, dispatchCrud } = crudReducer;

      dispatchCrud({ type: 'START' });
      Axios.post("https://account.api.duc168.com/duc-tran-api-account-graphql", {
        query: `mutation generateCode($usage: String!) {
                  generateCode(usage: $usage) {
                      id
                      code
                      usage
                      expireDate
                      used
                      limit
                      usedUser
                  }
              }`,
        variables: {
          // "data": { ...data, year: parseInt(data.year) },
          "usage": data.usage
        }
      }, {
        headers: {
          "duc-tran-api-account-token": appState.account.token
        }
      }).then(i => {
        const data = i.data;
        // console.log(data);
        if (data.data !== undefined && data.data !== null) {
          // const createCountry = data.data.createCountry;
          // console.log(createCountry);
          toast.success("Invite is generated successfully!");
        } else {
          console.log(data);
          toast.warning("Generate failed!");
        }
        dispatchCrud({ type: 'END' });
      }).catch(i => {
        console.log(i);
        dispatchCrud({ type: 'END' });
        toast.error("Can not generate Invite Code! Something goes wrong here!");
      })
    },
    loadOneCrud: (appReducer, crudReducer, setThisCrud) => {
      // eslint-disable-next-line
      const { appState, dispatchApp } = appReducer;
      // eslint-disable-next-line
      const { crudState, dispatchCrud } = crudReducer;
      dispatchCrud({ type: 'START' });
      Axios.post("https://account.api.duc168.com/duc-tran-api-account-graphql", {
        query: `query findCode($code: String!) {
                    findCode(code: $code) {
                        id
                        code
                        usage
                        expireDate
                        used
                        limit
                        usedUser
                        userId
                    }
                }`,
        variables: {
          "code": crudState.editId
        }
      }, {
        headers: {
          "duc-tran-api-account-token": appState.account.token
        }
      }).then(i => {
        const data = i.data;
        // console.log(data);
        if (data.data !== null && data.data !== undefined) {
          const findCode = data.data.findCode;
          // console.log(country);
          setThisCrud(findCode);
          dispatchCrud({ type: 'END' });
        } else {
          console.log('error ', data);
          dispatchCrud({ type: 'END' });
        }
      }).catch(i => {
        console.log(i);
        dispatchCrud({ type: 'END' });

      })
    },
    editCrud: (appReducer, crudReducer, data) => {
      // eslint-disable-next-line
      const { appState, dispatchApp } = appReducer;
      // eslint-disable-next-line
      const { crudState, dispatchCrud } = crudReducer;
      // dispatchCrud({ type: 'START' });
      // dispatchCrud({ type: 'END' });
      toast.warning("Can not edit Invite Code, please generate new code to use!");
    },
    deleteCrud: (appReducer, crudReducer, data) => {
      // eslint-disable-next-line
      const { appState, dispatchApp } = appReducer;
      // eslint-disable-next-line
      const { crudState, dispatchCrud } = crudReducer;
      // dispatchCrud({ type: 'START' });
      // dispatchCrud({ type: 'END' });
      toast.warning("Can not delete Invite Code!");

    }

  } as CrudSettingModel;

  const MODE = {
    INVITE_CODE: "INVITE CODE"
  }
  const [currentMode, setCurrentMode] = useState(MODE.INVITE_CODE);
  const isInviteCodeMode = currentMode === MODE.INVITE_CODE;

  const authSetting = {
    app: {
      title: 'invite.duc168.com',
      loginTitle: 'INVITE2DUC168'
    },
    components: {
      CustomComponentGenerator: () => {
        if (!!appState.auth) {
          return (
            <div className="width-100" style={{ marginTop: '20px' }}>

              <button type="button" className={'btn ' + (!!isInviteCodeMode ? 'btn-success' : 'btn-dark')} onClick={() => setCurrentMode(MODE.INVITE_CODE)}>{MODE.INVITE_CODE}</button>
              <hr></hr>
              {!!isInviteCodeMode ? <CRUD setting={inviteSetting} reducer={reducer} /> : ''}
            </div>
          )
        }
        return (
          <div>

          </div>
        );
      }
    },
    login: {
      url: "https://account.api.duc168.com/duc-tran-api-account-graphql",
      data: (username: string, password: string) => {
        return {
          query: `
          query login($username: String!, $password: String!) {
          login(username: $username, password: $password) {
              _id
              token
              username
              firstName
              lastName
              email
              role
          }
          }
          `,
          variables: {
            username: username,
            password: password
          }
        }
      },
      headers: {}
    },


  } as AuthSettingModel;

  return (
    <div className="App container">
      <AuthenticationLayer reducer={reducer} setting={authSetting} />
      <ToastContainer />
    </div>
  )
}

export default App;
