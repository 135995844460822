import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
function GenerateInviteCodeForm(props: any) {
    const { id: inputId, code: inputCode, expireDate: expireDate, usage: inputUsage, used: inputUsed, limit: inputLimit, usedUser: usedUser } = props.form;
    const { onSubmit, onSubmitDeleting } = props;
    const { register, handleSubmit, errors } = useForm();
    const inputExpireDate = expireDate === undefined ? '' : moment(new Date(parseInt(expireDate, 10))).format('DD-MM-YYYY HH:mm:ss');
    const inputUsedUser = usedUser === undefined ? '' : (JSON.parse(usedUser) as Array<string>).join(", ");
    const onFormSubmit = (data: any) => {        
        // console.log('on form submit, using handle submit from useForm, ', data);
        onSubmit(inputId === undefined || inputId === '' ? data : { id: inputId, ...data});
    }
    const onFormSubmitDeleting = (e: any) => {
        e.preventDefault();
        onSubmitDeleting({ id: inputId });
    }
    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="form-row">
            <div className="form-group col-md-6">
            <label htmlFor="usage">Usage</label>
            <input type="text" className="form-control" id="usage" name="usage" defaultValue={inputUsage} ref={register({ required: true })} />
            <p className="error">{errors.usage && "Usage is required"}</p>
            </div>
            <div className="form-group col-md-6">
            <label htmlFor="code">Code</label>
            <input type="text" className="form-control" id="code" name="code" defaultValue={inputCode} ref={register({ required: false })} readOnly/>
            <p className="error">{errors.code && "Code is required"}</p>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="expireDate">Expired Date</label>
            <input type="text" className="form-control" id="expireDate" name="expireDate" defaultValue={inputExpireDate} ref={register({ required: false })} readOnly />
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
            <label htmlFor="used">Used</label>
            <input type="number" className="form-control" id="used" name="used" defaultValue={inputUsed} ref={register({ required: false })} readOnly />
            </div>
            <div className="form-group col-md-6">
            <label htmlFor="limit">Limit</label>
            <input type="number" className="form-control" id="limit" name="limit" defaultValue={inputLimit} ref={register({ required: false })} readOnly/>
            </div>
        </div>
        <div className="form-row">
            <label htmlFor="usedUser">Used By Users</label>
            <input type="text" className="form-control" id="usedUser" name="usedUser" defaultValue={inputUsedUser} ref={register({ required: false })} readOnly />
        </div>
        
        <button type="submit" className="btn btn-primary">Submit</button>
        <hr></hr>
        { inputId !== undefined ? <button className="btn btn-danger" onClick={onFormSubmitDeleting}>Delete</button> : '' }
        </form>
    )
}

export default GenerateInviteCodeForm;